import {IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonSpinner,} from "@ionic/react";
import {Box} from "@mui/material";
import React, {useEffect} from "react";

import {APIConnector} from "@biss/lib/APIConnector";
import {Location} from "@biss/data/Types";
import not_found from "@biss/assets/not_found.png";
import {LocationFilterNotifier} from "@biss/lib/LocationFilterNotifier";
import LocationListItem from "@biss/components/LocationListItem";

import "@biss/components/styles/LocationList.sass";

export enum LocationListFetchState {
    LOADING,
    LOADED_HAS_MORE,
    LOADED_LAST_PAGE,
    NO_RESULTS,
    NETWORK_ERROR
}

interface LocationListProps {
    apiConnector: APIConnector;
    locationFilterNotifier: LocationFilterNotifier;
    locations: Location[];
    loadMoreLocations: () => void;
    locationFetchState: LocationListFetchState;
    onOpenLocation: (location: Location) => void;
}

const LocationList: React.FC<LocationListProps> = (props) => {
    useEffect(() => {
        props.loadMoreLocations();
    }, []);

    return (
        <>
            {(() => {
                switch (props.locationFetchState) {
                    case LocationListFetchState.LOADED_HAS_MORE:
                    case LocationListFetchState.LOADED_LAST_PAGE:
                        return (
                            <>
                                <Box className="ion-no-padding ion-padding-end locations-container">
                                    {props.locations.map((location, index) => (
                                        <LocationListItem key={index}
                                                          onOpenLocation={() => props.onOpenLocation(location)}
                                                          location={location}
                                                          apiConnector={props.apiConnector}
                                                          locationFilter={props.locationFilterNotifier.currentState}/>
                                    ))}
                                </Box>
                                <IonInfiniteScroll onIonInfinite={(ev) => {
                                    props.loadMoreLocations();
                                    setTimeout(() => ev.target.complete(), 500);
                                }}>
                                    {props.locationFetchState === LocationListFetchState.LOADED_HAS_MORE &&
                                        <IonInfiniteScrollContent/>
                                    }
                                </IonInfiniteScroll>
                            </>
                        );
                    case LocationListFetchState.LOADING:
                        return (
                            <Box display="flex" justifyContent="center">
                                <IonSpinner/>
                            </Box>
                        );
                    case LocationListFetchState.NO_RESULTS:
                        return (
                            <Box className="center-status-box">
                                <IonImg className="center-status-img" src={not_found}/>
                                <h2>Hoppla!</h2>
                                Suche ergab leider keine Ergebnisse...
                            </Box>
                        );
                    case LocationListFetchState.NETWORK_ERROR:
                        break;
                }
            })()}
        </>
    );
};

export default LocationList;