import {IonIcon, IonImg, IonTitle, IonToggle, IonToolbar} from "@ionic/react";
import {Box} from "@mui/material";
import {moonOutline, sunnyOutline} from "ionicons/icons";
import React, {useEffect, useState} from "react";

import loader from "@biss/assets/loader.png";

import "@biss/components/styles/Header.sass";

const Header: React.FC = () => {
    const [isDark, setIsDark] = useState(false);

    useEffect(() => {
        const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
        initializeDarkTheme(prefersDark.matches);
        prefersDark.addEventListener("change", (mediaQuery) => initializeDarkTheme(mediaQuery.matches));
    }, []);

    function initializeDarkTheme(isDark: boolean) {
        setIsDark(isDark);
        toggleDarkTheme(isDark);
    }

    function toggleChange() {
        toggleDarkTheme(!isDark);
        setIsDark(!isDark);
    }

    function toggleDarkTheme(shouldAdd: boolean) {
        document.body.classList.toggle("dark", shouldAdd);
    }

    return (
        <IonToolbar className="biss-header">
            <Box className="biss-header-right">
                <IonToggle className="theme-toggle" color="warning" checked={!isDark} onIonChange={toggleChange}/>
                {isDark && <IonIcon color="light" className="theme-toggle-icon theme-toggle-icon-dark" icon={moonOutline}/>}
                {!isDark && <IonIcon className="theme-toggle-icon theme-toggle-icon-light" icon={sunnyOutline}/>}
            </Box>
            <IonTitle>
                <IonImg className="biss-logo" src={loader} alt="Biss Logo"/>
            </IonTitle>
        </IonToolbar>
    );
};

export default Header;