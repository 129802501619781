import {
    buildLocationFilterParameters,
    Canton,
    Fish,
    FishLink,
    Location,
    LocationFilter,
    TravelMethod
} from "@biss/data/Types";

export class APIConnector {
    public static readonly serverUrl = process.env.NODE_ENV === "production" ?
        process.env.REACT_APP_PRODUCTION_BACKEND : process.env.REACT_APP_DEVELOPMENT_BACKEND;

    public static readonly serveUrl = "https://beta.biss-app.ch/";

    static getLocationImageByName(name: string, number: number) {
        return `${APIConnector.serverUrl}/static/locations/${APIConnector.normalizeImageName(name)}_${number}.jpg`;
    }

    static getCantonFlagUrl(canton: Canton) {
        return `/assets/cantons/Wappen_${canton.name.replace(" ", "_")}_matt.svg.png`;
    }

    static getFishImageByName(name: string) {
        // Use the same image for all trouts
        if (name.toLowerCase().includes("forelle")) {
            return `${APIConnector.serverUrl}/static/fish/forelle.png`;
        }
        return `${APIConnector.serverUrl}/static/fish/${APIConnector.normalizeImageName(name)}.png`;
    }

    async getAllFish(): Promise<Fish[]> {
        const fish = await APIConnector.getPath<Fish[]>("/fish");
        if (!fish) return [];
        return fish;
    }

    async getAllCantons(): Promise<Canton[]> {
        const cantons = await APIConnector.getPath<Canton[]>("/cantons");
        if (!cantons) return [];
        return cantons;
    }

    async getAllTravelMethods(): Promise<TravelMethod[]> {
        const travelMethods = await APIConnector.getPath<TravelMethod[]>("/travel_methods");
        if (!travelMethods) return [];
        return travelMethods;
    }

    async getLocations(locationFilter: LocationFilter, page: number): Promise<Location[] | null> {
        const locations = await APIConnector.get<Location[]>(
            new URL(`${APIConnector.serverUrl}/locations?${buildLocationFilterParameters(locationFilter, page, 100)}`));
        if (locations === undefined) return [];
        return locations;
    }

    async getLocation(locationUuid: string): Promise<Location | null | undefined> {
        return APIConnector.getPath<Location>(`/locations/${locationUuid}`);
    }

    async getAllFishForLocation(locationUuid: string): Promise<FishLink[] | null> {
        const fish = await APIConnector.getPath<FishLink[]>(`/locations/${locationUuid}/fish`);
        if (fish === undefined) return [];
        return fish;
    }

    /* Replaces all umlauts with their 2-letter version and all spaces with underscores */
    private static normalizeImageName = (name: string) => name.toLowerCase()
        .replace(/ /g, "_")
        .replace(/\u00e4/g, "ae")
        .replace(/\u00f6/g, "oe")
        .replace(/\u00fc/g, "ue");

    private static async getPath<T>(path: string): Promise<T | null | undefined> {
        return this.get(new URL(`${APIConnector.serverUrl}${path}`));
    }

    private static async get<T>(url: URL): Promise<T | null | undefined> {
        try {
            const response = await fetch(url, {
                method: "GET"
            });
            if (response.status !== 200) {
                return undefined;
            }
            return await response.json();
        } catch (e) {
            return null;
        }
    }
}