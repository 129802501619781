import {IonCol, IonGrid, IonRow} from "@ionic/react";
import React from "react";

import {Location} from "@biss/data/Types";

import "./styles/DatasheetEntry.sass";

interface DatasheetEntryProps {
    title: string;
    titlePlural?: string;
    value: string;
}

interface DatasheetProps {
    location: Location;
}

const Datasheet: React.FC<DatasheetProps> = (props: DatasheetProps) => {

    return (
        <IonGrid>
            <DatasheetEntry title="Gemeinde" titlePlural="Gemeinden" value={props.location.residence}/>
            <DatasheetEntry title="m ü. M." value={props.location.elevation}/>
            <DatasheetEntry title="Länge" value={props.location.length}/>
            <DatasheetEntry title="Breite" value={props.location.width}/>
            <DatasheetEntry title="Oberfläche" value={props.location.surfaceArea}/>
            <DatasheetEntry title="Einzugsgebiet" value={props.location.catchmentArea}/>
            <DatasheetEntry title="Mittlere Tiefe" value={props.location.averageDepth}/>
            <DatasheetEntry title="Tiefste Stelle" value={props.location.maxDepth}/>
            <DatasheetEntry title="Wasservolumen" value={props.location.waterVolume}/>
            <DatasheetEntry title="Aufenthaltszeit" value={props.location.residenceTime}/>
            <DatasheetEntry title="Inseln" value={props.location.islands}/>
            <DatasheetEntry title="Abfluss" titlePlural="Abflüsse" value={props.location.outflows}/>
            <DatasheetEntry title="Zufluss" titlePlural="Zuflüsse" value={props.location.inflows}/>
        </IonGrid>
    );
};

const DatasheetEntry: React.FC<DatasheetEntryProps> = (props: DatasheetEntryProps) => {
    function hasMultiple(value: string) {
        return value.includes(",");
    }

    function formatValue(value: string) {
        return value.replaceAll(",", ", ");
    }

    return props.value !== null ? (
        <IonRow className="datasheet-row">
            <IonCol className="datasheet-column bold">
                {hasMultiple(props.value) ? props.titlePlural : props.title}
            </IonCol>
            <IonCol className="datasheet-column">{formatValue(props.value)}</IonCol>
        </IonRow>
    ) : <></>;
};

export default Datasheet;