import Home from "@biss/components/Home";
import {IonApp, IonRouterOutlet, setupIonicReact} from "@ionic/react";
import {IonReactRouter} from "@ionic/react-router";
import React, {useEffect} from "react";
import {Route} from "react-router-dom";

import {APIConnector} from "./lib/APIConnector";
import {LocationFilterNotifier} from "./lib/LocationFilterNotifier";

import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import "./theme/Fonts.sass";
import "./theme/Theming.sass";

const App: React.FC = () => {
    const apiConnector = new APIConnector();
    const locationFilterNotifier = new LocationFilterNotifier();
    
    useEffect(() => {
        // setupIonicReact({
        //     mode: "ios"
        // });
        setupIonicReact();
    }, []);

    return (
        <IonApp>
            <IonReactRouter>
                <IonRouterOutlet>
                    <Route path="/" exact={true}>
                        <Home apiConnector={apiConnector} locationFilterNotifier={locationFilterNotifier}/>
                    </Route>
                    <Route path="/locations/:uuid" exact={true}>
                        <Home apiConnector={apiConnector} locationFilterNotifier={locationFilterNotifier}/>
                        {/*<LocationView apiConnector={apiConnector} />*/}
                    </Route>
                </IonRouterOutlet>
            </IonReactRouter>
        </IonApp>
    );
};

export default App;
