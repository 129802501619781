import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonImg,
    IonRippleEffect,
    IonText,
} from "@ionic/react";
import {Box} from "@mui/material";
import React from "react";
import Highlighter from "react-highlight-words";

import PropertyChip, {ChipType, LocationProperty} from "@biss/components/PropertyChip";
import {Location, LocationFilter} from "@biss/data/Types";
import {APIConnector} from "@biss/lib/APIConnector";
import placeholder from "@biss/assets/lake.jpg";

import "@biss/components/styles/LocationListItem.sass";

interface LocationListItemProps {
    apiConnector: APIConnector;
    locationFilter: LocationFilter;
    location: Location;
    onOpenLocation: () => void;
}

const LocationListItem: React.FC<LocationListItemProps> = (props) => {
    return (
        <IonCard onClick={props.onOpenLocation}
                 className="ion-margin-bottom location-card ion-activatable">
            <IonRippleEffect></IonRippleEffect>
            {props.location.imageCount === 0 ?
                <IonImg className="location-image" src={placeholder}/> :
                <IonImg className="location-image"
                        src={APIConnector.getLocationImageByName(props.location.name, 1)}/>
            }
            <IonCardHeader>
                <Box marginTop={1} display="flex" alignItems="center" flexWrap="wrap" flexDirection="row">
                    <PropertyChip type={ChipType.DISPLAY_DISABLE}
                                  small={true}
                                  active={props.location.withoutSana}
                                  property={LocationProperty.RequireWithoutSana}/>
                    <PropertyChip type={ChipType.DISPLAY_DISABLE}
                                  small={true}
                                  active={props.location.hasCamping}
                                  property={LocationProperty.RequireCamping}/>
                    <PropertyChip type={ChipType.DISPLAY_DISABLE}
                                  small={true}
                                  active={props.location.hasFreeFishing}
                                  property={LocationProperty.RequireFreeFishing}/>
                    <PropertyChip type={ChipType.DISPLAY_DISABLE}
                                  small={true}
                                  active={props.location.hasBoatRent}
                                  property={LocationProperty.RequireBoatRent}/>
                    <PropertyChip type={ChipType.DISPLAY_DISABLE}
                                  small={true}
                                  active={props.location.hasRestaurant}
                                  property={LocationProperty.RequireRestaurant}/>
                </Box>
                <IonCardTitle>
                    <Highlighter autoEscape={true}
                                 searchWords={[props.locationFilter.searchQuery]}
                                 textToHighlight={props.location.name}/>
                </IonCardTitle>
                <IonCardSubtitle>
                    <Box display="flex" gap="8px" flexWrap="wrap">
                        {props.location.cantons.map((canton, index) => (
                            <Box key={index} display="flex" alignItems="center">
                                <IonImg className="canton-flag-img"
                                        src={APIConnector.getCantonFlagUrl(canton)}/>
                                <IonText>{canton.name}</IonText>
                            </Box>
                        ))}
                    </Box>
                </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
                {props.location.informationSummary}
            </IonCardContent>
        </IonCard>
    );
};

export default LocationListItem;
