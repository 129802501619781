import {
    boatOutline,
    carOutline,
    documentTextOutline,
    fishOutline,
    flameOutline,
    restaurant,
    trainOutline, walkOutline
} from "ionicons/icons";
import {IonChip, IonIcon, IonLabel} from "@ionic/react";
import React from "react";

interface PropertyPair {
    name: string;
    color: string;
    icon: string;
}

export enum LocationProperty {
    RequireBoatRent = "requireBoatRent",
    RequireCamping = "requireCamping",
    RequireWithoutSana = "requireWithoutSana",
    RequireFreeFishing = "requireFreeFishing",
    RequireRestaurant = "requireRestaurant"
}

export enum ChipType {
    DISPLAY_HIDE,
    DISPLAY_DISABLE,
    FILTER,
}

export const PropertyPairMap: Record<LocationProperty, PropertyPair> = {
    [LocationProperty.RequireBoatRent]: {name: "Bootsmiete", color: "secondary", icon: boatOutline},
    [LocationProperty.RequireCamping]: {name: "Campingplatz", color: "tertiary", icon: flameOutline},
    [LocationProperty.RequireWithoutSana]: {name: "z.T. Sana nicht benötigt", color: "primary", icon: documentTextOutline},
    [LocationProperty.RequireFreeFishing]: {name: "Freifischen", color: "warning", icon: fishOutline},
    [LocationProperty.RequireRestaurant]: {name: "Restaurant", color: "success", icon: restaurant},
} as const;

export const TravelIconMap = new Map([
    ["drive", carOutline],
    ["transit", trainOutline],
    ["walk", walkOutline]
]);

interface PropertyChipProps {
    type: ChipType;
    active: boolean;
    property: LocationProperty;
    iconAddOverride?: string;
    iconRemoveOverride?: string;
    onClick?: () => void;
    small: boolean;
}

const PropertyChip: React.FC<PropertyChipProps> = (props) => {
    function getIcon(): string {
        return props.active ?
            (props.iconRemoveOverride ? props.iconRemoveOverride : PropertyPairMap[props.property].icon) :
            (props.iconAddOverride ? props.iconAddOverride : PropertyPairMap[props.property].icon);
    }

    function isDisabled() {
        if (props.type === ChipType.DISPLAY_DISABLE) {
            return !props.active;
        }
        return false;
    }

    function isHidden() {
        if (props.type === ChipType.DISPLAY_HIDE) {
            return !props.active;
        }
        return false;
    }

    function isOutlined() {
        if (props.type === ChipType.FILTER) {
            return !props.active;
        }
        return false;
    }

    return !isHidden() ? (
        <IonChip key={props.property}
                 disabled={isDisabled()}
                 outline={isOutlined()}
                 onClick={props.onClick}
                 color={PropertyPairMap[props.property].color}>
            <IonIcon className={`${props.small && "small-property-chip"}`} icon={getIcon()}/>
            {!props.small && <IonLabel> {PropertyPairMap[props.property].name} </IonLabel>}
        </IonChip>
    ) : <></>;
};

export default PropertyChip;