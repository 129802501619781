import {IonChip, IonIcon, IonImg, IonLabel} from "@ionic/react";
import {closeOutline, fishOutline} from "ionicons/icons";
import React, {useEffect, useState} from "react";

import {LocationListFetchState} from "@biss/components/LocationList";
import {LocationProperty, PropertyPairMap, TravelIconMap} from "@biss/components/PropertyChip";
import {LocationFilter} from "@biss/data/Types";
import {APIConnector} from "@biss/lib/APIConnector";
import {LocationFilterNotifier} from "@biss/lib/LocationFilterNotifier";

interface FilterPreviewProps {
    fetchState: LocationListFetchState;
    locationFilterNotifier: LocationFilterNotifier;
}

const FilterPreview: React.FC<FilterPreviewProps> = (props: FilterPreviewProps) => {
    const [filterState, setFilterState] = useState<LocationFilter>(props.locationFilterNotifier.currentState);

    useEffect(() => {
        props.locationFilterNotifier.registerHandler("filterPreview", (filter: LocationFilter) => {
            setFilterState(filter);
        });

        return () => props.locationFilterNotifier.unregisterHandler("filterPreview");
    }, []);

    function updateFilter(filterState: LocationFilter) {
        // Block filter change during loading
        if (props.fetchState === LocationListFetchState.LOADING) return;

        props.locationFilterNotifier.trigger(filterState);
    }

    return (
        <>
            {filterState.propertiesFilter.has(LocationProperty.RequireBoatRent) &&
                <IonChip className="filter-preview-chip"
                         color={PropertyPairMap[LocationProperty.RequireBoatRent].color}
                         onClick={() => updateFilter({
                             ...filterState,
                             propertiesFilter: filterState.propertiesFilter.toggle(LocationProperty.RequireBoatRent)
                         })}>
                    <IonIcon icon={PropertyPairMap[LocationProperty.RequireBoatRent].icon}/>
                    <IonLabel> {PropertyPairMap[LocationProperty.RequireBoatRent].name} </IonLabel>
                    <IonIcon icon={closeOutline}/>
                </IonChip>
            }
            {filterState.propertiesFilter.has(LocationProperty.RequireCamping) &&
                <IonChip className="filter-preview-chip"
                         color={PropertyPairMap[LocationProperty.RequireCamping].color}
                         onClick={() => updateFilter({
                             ...filterState,
                             propertiesFilter: filterState.propertiesFilter.toggle(LocationProperty.RequireCamping)
                         })}>
                    <IonIcon icon={PropertyPairMap[LocationProperty.RequireCamping].icon}/>
                    <IonLabel> {PropertyPairMap[LocationProperty.RequireCamping].name} </IonLabel>
                    <IonIcon icon={closeOutline}/>
                </IonChip>
            }
            {filterState.propertiesFilter.has(LocationProperty.RequireWithoutSana) &&
                <IonChip className="filter-preview-chip"
                         color={PropertyPairMap[LocationProperty.RequireWithoutSana].color}
                         onClick={() => updateFilter({
                             ...filterState,
                             propertiesFilter: filterState.propertiesFilter.toggle(LocationProperty.RequireWithoutSana)
                         })}>
                    <IonIcon icon={PropertyPairMap[LocationProperty.RequireWithoutSana].icon}/>
                    <IonLabel> {PropertyPairMap[LocationProperty.RequireWithoutSana].name} </IonLabel>
                    <IonIcon icon={closeOutline}/>
                </IonChip>
            }
            {filterState.propertiesFilter.has(LocationProperty.RequireFreeFishing) &&
                <IonChip className="filter-preview-chip"
                         color={PropertyPairMap[LocationProperty.RequireFreeFishing].color}
                         onClick={() => updateFilter({
                             ...filterState,
                             propertiesFilter: filterState.propertiesFilter.toggle(LocationProperty.RequireFreeFishing)
                         })}>
                    <IonIcon icon={PropertyPairMap[LocationProperty.RequireFreeFishing].icon}/>
                    <IonLabel> {PropertyPairMap[LocationProperty.RequireFreeFishing].name} </IonLabel>
                    <IonIcon icon={closeOutline}/>
                </IonChip>
            }
            {filterState.propertiesFilter.has(LocationProperty.RequireRestaurant) &&
                <IonChip className="filter-preview-chip"
                         color={PropertyPairMap[LocationProperty.RequireRestaurant].color}
                         onClick={() => updateFilter({
                             ...filterState,
                             propertiesFilter: filterState.propertiesFilter.toggle(LocationProperty.RequireRestaurant)
                         })}>
                    <IonIcon icon={PropertyPairMap[LocationProperty.RequireRestaurant].icon}/>
                    <IonLabel> {PropertyPairMap[LocationProperty.RequireRestaurant].name} </IonLabel>
                    <IonIcon icon={closeOutline}/>
                </IonChip>
            }

            {filterState.travelMethodFilter.toList().map((travelMethod, i) => (
                <IonChip key={i}
                         className="filter-preview-chip"
                         onClick={() => updateFilter({
                             ...filterState,
                             travelMethodFilter: filterState.travelMethodFilter.toggle(travelMethod)
                         })}>
                    <IonIcon icon={TravelIconMap.get(travelMethod.type)}/>
                    <IonLabel> {travelMethod.name} </IonLabel>
                    <IonIcon icon={closeOutline}/>
                </IonChip>
            ))}

            {filterState.fishFilter.toList().map((fish, i) => (
                <IonChip key={i}
                         className="filter-preview-chip"
                         onClick={() => updateFilter({
                             ...filterState,
                             fishFilter: filterState.fishFilter.toggle(fish)
                         })}>
                    <IonIcon icon={fishOutline}/>
                    <IonLabel> {fish.name} </IonLabel>
                    <IonIcon icon={closeOutline}/>
                </IonChip>
            ))}

            {filterState.cantonFilter.toList().map((canton, i) => (
                <IonChip key={i}
                         className="filter-preview-chip"
                         onClick={() => updateFilter({
                             ...filterState,
                             cantonFilter: filterState.cantonFilter.toggle(canton)
                         })}>
                    <IonImg className="canton-flag-chip-img" src={APIConnector.getCantonFlagUrl(canton)}/>
                    <IonLabel> {canton.name} </IonLabel>
                    <IonIcon icon={closeOutline}/>
                </IonChip>
            ))}

        </>
    );
};

export default FilterPreview;