import {LocationProperty} from "../components/PropertyChip";
import {ToggleSet} from "../lib/Helpers";

export interface Location {
    uuid: string;
    name: string;
    information: string;
    informationSummary: string;
    informationPatent: string;
    informationRules: string;
    residence: string;
    patentShopUrl: string;
    hasCamping: boolean;
    hasFreeFishing: boolean;
    hasRestaurant: boolean;
    hasBoatRent: boolean;
    withoutSana: boolean;
    longitude: number;
    latitude: number;
    length: string;
    width: string;
    surfaceArea: string;
    catchmentArea: string;
    averageDepth: string;
    maxDepth: string;
    waterVolume: string;
    residenceTime: string;
    elevation: string;
    islands: string;
    outflows: string;
    inflows: string;
    imageCount: number;
    cantons: Canton[];
    travelMethods: TravelMethod[];
    fishLinks: FishLink[];
}

// We trust the developer here that these keys all exist on Location
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const datasheetProperties: [keyof Location] = [
    "length", "width", "surfaceArea", "catchmentArea",
    "averageDepth", "maxDepth", "waterVolume", "residenceTime",
    "elevation", "islands", "outflows", "inflows"
];

export function locationHasDatasheet(location?: Location) {
    if (!location) return false;
    return datasheetProperties.some((prop: keyof Location) => location[prop] !== null);
}

export interface LocationFilter {
    searchQuery: string;
    cantonFilter: ToggleSet<Canton>;
    fishFilter: ToggleSet<Fish>;
    travelMethodFilter: ToggleSet<TravelMethod>;
    propertiesFilter: ToggleSet<LocationProperty>;
}

export function buildLocationFilterParameters(filter: LocationFilter, page: number, pageSize: number) {
    const params = new URLSearchParams({
        "searchQuery": filter.searchQuery,
        "page": page.toString(),
        "pageSize": pageSize.toString()
    });
    filter.propertiesFilter.forEach(v => params.append("propertiesFilter", v));
    filter.travelMethodFilter.forEach(v => params.append("travelMethodFilter", v.uuid));
    filter.cantonFilter.forEach(v => params.append("cantonFilter", v.uuid));
    filter.fishFilter.forEach(v => params.append("fishFilter", v.uuid));

    return params;
}

export const emptyLocationFilter = (): LocationFilter => ({
    searchQuery: "",
    cantonFilter: new ToggleSet(),
    fishFilter: new ToggleSet(),
    travelMethodFilter: new ToggleSet(),
    propertiesFilter: new ToggleSet()
});

export interface Canton {
    uuid: string;
    name: string;
}

export interface TravelMethod {
    uuid: string;
    name: string;
    type: string;
}

export interface Fish {
    uuid: string;
    name: string;
}

export interface FishLink {
    fish: Fish;

    minimumSize?: number;
    protectionStart?: string;
    protectionEnd?: string;
}