import Datasheet from "@biss/components/DatasheetEntry";
import FishListEntry from "@biss/components/FishListEntry";

import {Clipboard} from "@capacitor/clipboard";
import {Share} from "@capacitor/share";
import {
    IonButton,
    IonButtons,
    IonCardSubtitle,
    IonCardTitle,
    IonChip,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonImg,
    IonLabel,
    IonList,
    IonPage,
    IonRow,
    IonText,
    IonToolbar, useIonRouter
} from "@ionic/react";
import {Box, Tooltip, useMediaQuery} from "@mui/material";
import {APIProvider, Map, Marker} from "@vis.gl/react-google-maps";
import {
    caretDownOutline,
    caretUpOutline,
    checkmarkOutline,
    clipboardOutline,
    fishOutline,
    informationCircleOutline,
    mapOutline,
    openOutline,
    shareSocialOutline,
    statsChartOutline
} from "ionicons/icons";
import React, {Fragment, useEffect, useState} from "react";

import "swiper/css";
import {useParams} from "react-router";
import {Keyboard, Zoom} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";

import {APIConnector} from "@biss/lib/APIConnector";
import {Location, locationHasDatasheet} from "@biss/data/Types";
import PropertyChip, {ChipType, LocationProperty, TravelIconMap} from "@biss/components/PropertyChip";
import "./styles/LocationView.sass";

interface LocationViewProps {
    location?: Location;
    onClose?: () => void;
    apiConnector?: APIConnector;
}

interface TextClamperProps {
    text: string;
}

const LocationView: React.FC<LocationViewProps> = (props) => {
    const [isCopyToClipboard, setCopyToClipboard] = useState(false);
    const [canShare, setCanShare] = useState(false);

    const [location, setLocation] = useState<Location>(null);

    const isMobile = useMediaQuery("(max-width: 767px)");
    const isMedium = useMediaQuery("(min-width: 768px)");
    const isLarge = useMediaQuery("(min-width: 1200px)");

    const hasDatasheet = locationHasDatasheet(location);
    const slidesPerView = isLarge ? 3 : isMedium ? 2 : 1;

    const params: {uuid: string} = useParams();

    const router = useIonRouter();

    useEffect(() => {
        Share.canShare().then(res => setCanShare(res.value));
        if (params && params.uuid) {
            props.apiConnector?.getLocation(params.uuid).then(loc => {
                setLocation(loc);
            });
        } else {
            setLocation(props.location);
        }
    }, []);

    const openGoogleMaps = (location: Location) =>
        window.open(`https://www.google.com/maps/search/?api=1&query=${location.name}+${location.residence}`);

    async function onShare() {
        if (isCopyToClipboard) return;

        const url = APIConnector.serveUrl + window.location.search;

        if ((await Share.canShare()).value) {
            await Share.share({
                url: url,
                dialogTitle: "Teile diesen See mit deinen Freunden",
            });
        } else {
            await Clipboard.write({
                string: url
            });
        }
        setCopyToClipboard(true);
        setTimeout(() => setCopyToClipboard(false), 2000);
    }

    return location ? (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonButton onClick={props.onClose ? props.onClose : () => router.push("/")}>
                            Zurück
                        </IonButton>
                    </IonButtons>
                    <IonButtons slot="end">
                        <Tooltip open={isCopyToClipboard} title="Link kopiert!" placement="top">
                            <IonButton onClick={onShare}>
                                <IonIcon slot="icon-only"
                                         icon={canShare ? shareSocialOutline : clipboardOutline}/>
                                {isCopyToClipboard &&
                                    <IonIcon slot="icon-only"
                                             icon={checkmarkOutline}
                                             style={{position: "fixed", fontSize: "1em"}}/>
                                }
                            </IonButton>
                        </Tooltip>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent className="ion-padding-start ion-padding-end">
                <Box marginBottom={2} marginRight={-2} marginLeft={-2}>
                    <Swiper modules={[Zoom, Keyboard]}
                            keyboard={true}
                            zoom={true}
                            slidesPerView={slidesPerView}
                            spaceBetween={16} centeredSlides={location.imageCount === 1}>
                        {Array(location.imageCount).fill(0).map((v, i) => (
                            <SwiperSlide key={i}>
                                <IonImg src={APIConnector.getLocationImageByName(location.name, i + 1)}
                                        alt={`Bild #${i + 1} von ${location.name}`}/>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Box>
                <IonCardSubtitle>
                    <Box display="flex" flexWrap="wrap">
                        <Box marginRight={1}>{location.residence}</Box>
                        {location.cantons.length &&
                            <>
                                <span>(</span>
                                {location.cantons.map((canton, i) => (
                                    <Fragment key={i}>
                                        <IonImg className="canton-flag-chip-img"
                                                src={APIConnector.getCantonFlagUrl(canton)}/>
                                        <IonLabel> {canton.name} </IonLabel>
                                        {i !== location.cantons.length - 1 &&
                                            <span className="canton-separating-comma">,</span>
                                        }
                                    </Fragment>
                                ))}
                                <span>)</span>
                            </>
                        }
                    </Box>
                </IonCardSubtitle>
                <IonCardTitle><h2>{location.name}</h2></IonCardTitle>
                <Box display="flex" flexWrap="wrap" marginBottom={2}>
                    <PropertyChip small={false}
                                  type={ChipType.DISPLAY_HIDE}
                                  active={location.withoutSana}
                                  property={LocationProperty.RequireWithoutSana}/>
                    <PropertyChip small={false}
                                  type={ChipType.DISPLAY_HIDE}
                                  active={location.hasCamping}
                                  property={LocationProperty.RequireCamping}/>
                    <PropertyChip small={false}
                                  type={ChipType.DISPLAY_HIDE}
                                  active={location.hasFreeFishing}
                                  property={LocationProperty.RequireFreeFishing}/>
                    <PropertyChip small={false}
                                  type={ChipType.DISPLAY_HIDE}
                                  active={location.hasBoatRent}
                                  property={LocationProperty.RequireBoatRent}/>
                    <PropertyChip small={false}
                                  type={ChipType.DISPLAY_HIDE}
                                  active={location.hasRestaurant}
                                  property={LocationProperty.RequireRestaurant}/>
                </Box>
                <IonText className="location-information">
                    {isMobile ?
                        <TextClamper text={location.information}/>
                        : <span className="location-information-text">{location.information}</span>

                    }
                </IonText>

                <IonGrid>
                    <IonRow>
                        <IonCol size="12" sizeXl={hasDatasheet ? "8" : "12"}>
                            <Box display="flex" alignItems="center" gap={1}>
                                <IonIcon size="large" color="primary" icon={fishOutline}/>
                                <h2> Fische </h2>
                            </Box>
                            <IonList>
                                {location.fishLinks.map((fish, index) =>
                                    <FishListEntry key={index} fishLink={fish}/>
                                )}
                            </IonList>
                        </IonCol>
                        {hasDatasheet &&
                            <IonCol size="12" sizeXl="4">
                                <Box display="flex" alignItems="center" gap={1}>
                                    <IonIcon size="large" color="primary" icon={statsChartOutline}/>
                                    <h2> Datenblatt </h2>
                                </Box>
                                <IonGrid>
                                    <Datasheet location={location}/>
                                </IonGrid>
                            </IonCol>
                        }
                    </IonRow>
                </IonGrid>
                <IonGrid className="ion-no-padding">
                    <IonRow>
                        <IonCol size="12" sizeSm="12" sizeMd="12" sizeLg="6">
                            <Box display="flex" alignItems="center">
                                <Box display="flex" alignItems="center" gap={1}>
                                    <IonIcon size="large" color="primary" icon={mapOutline}/>
                                    <h2> Karte (Google Maps </h2>
                                </Box>
                                <IonButton className="ion-no-padding" fill="clear"
                                           onClick={() => openGoogleMaps(location)}>
                                    <IonIcon slot="icon-only" icon={openOutline}/>
                                </IonButton>
                                <h2>)</h2>
                            </Box>
                            <Box height="400px" margin={2}>
                                <APIProvider apiKey="AIzaSyCp_LVNBE2e1rGm8H3qAaAPr35Q_catRFo">
                                    <Map center={{
                                        lng: location.latitude,
                                        lat: location.longitude
                                    }} zoom={10}>
                                        <Marker position={{
                                            lng: location.latitude,
                                            lat: location.longitude
                                        }}/>
                                    </Map>
                                </APIProvider>
                            </Box>
                        </IonCol>
                        <IonCol>
                            <Box>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <IonIcon size="large" color="primary" icon={informationCircleOutline}/>
                                    <h2> Weitere Informationen </h2>
                                </Box>
                                <Box className="ion-padding-start ion-padding-end ion-padding-bottom">
                                    <h3>Anreise</h3>
                                    <Box marginBottom={2}>
                                        {location.travelMethods.map((travelMethod, index) =>
                                            <IonChip key={index}
                                                     color="primary">
                                                <IonIcon icon={TravelIconMap.get(travelMethod.type)}/>
                                                <IonLabel> {travelMethod.name} </IonLabel>
                                            </IonChip>
                                        )}
                                    </Box>
                                    <h3>Patentbezug</h3>
                                    <Box marginLeft={2}>
                                        <span dangerouslySetInnerHTML={{__html: location.informationPatent}}/>
                                    </Box>
                                    <h3>Vorschriften</h3>
                                    <Box marginLeft={2}>
                                        <span dangerouslySetInnerHTML={{__html: location.informationRules}}/>
                                    </Box>
                                    <Box marginTop={4}>
                                        <IonButton expand="block"
                                                   onClick={() => window.open(location.patentShopUrl)}>
                                            <IonIcon slot="start" icon={fishOutline}/>
                                            <IonText> Patentkauf </IonText>
                                        </IonButton>
                                    </Box>
                                </Box>
                            </Box>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    ) : <></>;
};

const TextClamper: React.FC<TextClamperProps> = (props: TextClamperProps) => {
    const [isExpanded, setExpanded] = useState(false);

    const isClamped = () => props.text.length > 100 && !isExpanded;

    return (
        <>
            <Box className={`location-information-text ${isClamped() ? "text-clamped" : ""}`}>{props.text}</Box>
            <IonButton fill="clear" expand="block" onClick={() => setExpanded(!isExpanded)}>
                {isClamped() ?
                    <span>Mehr erfahren</span> :
                    <span>Weniger anzeigen</span>
                }
                <IonIcon icon={isClamped() ? caretDownOutline : caretUpOutline} slot="end"/>
            </IonButton>
        </>
    );
};

export default LocationView;
