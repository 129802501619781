import {App as CapacitorApp} from "@capacitor/app";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonImg,
    IonMenu,
    IonModal,
    IonPage,
    IonSplitPane,
    IonToolbar
} from "@ionic/react";
import {Box} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import Media from "react-media";

import network_error from "@biss/assets/network_error.png";
import FilterComponent from "@biss/components/FilterComponent";
import Header from "@biss/components/Header";
import LocationView from "@biss/components/LocationView";
import {getQueryParameter, useResource} from "@biss/lib/Helpers";
import SearchBar from "@biss/components/SearchBar";
import FilterPreview from "@biss/components/FilterPreview";
import LocationList, {LocationListFetchState} from "@biss/components/LocationList";
import {APIConnector} from "@biss/lib/APIConnector";
import {LocationFilterNotifier} from "@biss/lib/LocationFilterNotifier";
import {Canton, emptyLocationFilter, Fish, Location, LocationFilter, TravelMethod} from "@biss/data/Types";

import "@biss/components/styles/Home.sass";

interface HomeProps {
    apiConnector: APIConnector;
    locationFilterNotifier: LocationFilterNotifier;
}

const Home: React.FC<HomeProps> = (props) => {
    const [fetchState, setFetchState] = useState<LocationListFetchState>(LocationListFetchState.LOADING);
    const [currentPage, setCurrentPage] = useState(1);
    const [locations, setLocations] = useState<Location[]>([]);
    const [locationFilter, setCurrentFilter] = useState<LocationFilter>(emptyLocationFilter());
    const locationsRef = useRef<Location[]>();
    locationsRef.current = locations;

    // Modal states
    const [openLocation, setOpenLocation] = useState<Location | null>(null);
    const openLocationRef = useRef<Location | null>();
    openLocationRef.current = openLocation;
    const locationDialogRef = useRef();

    const [isFilterModalOpen, setFilterModalOpen] = useState<boolean>(false);
    const isFilterModalOpenRef = useRef<boolean>();
    isFilterModalOpenRef.current = isFilterModalOpen;

    // Static resources loaded once
    const allFish = useResource<Fish[]>([], () => props.apiConnector.getAllFish());
    const allCantons = useResource<Canton[]>([], () => props.apiConnector.getAllCantons());
    const allTravelMethods = useResource<TravelMethod[]>([], () => props.apiConnector.getAllTravelMethods());

    useEffect(() => {
        props.locationFilterNotifier.registerHandler("main", async (filter: LocationFilter) => {
            setFetchState(LocationListFetchState.LOADING);
            setLocations([]);
            setCurrentFilter(filter);

            props.apiConnector.getLocations(filter, 1).then((fetchedLocations: Location[] | null) => {
                if (!fetchedLocations) {
                    setFetchState(LocationListFetchState.NETWORK_ERROR);
                    return;
                }

                if (fetchedLocations.length) {
                    setLocations(fetchedLocations);
                    setCurrentPage(2);

                    // We assume that if the server returned less than 10 results, we hit the last page of results
                    setFetchState(fetchedLocations.length === 10 ?
                        LocationListFetchState.LOADED_HAS_MORE :
                        LocationListFetchState.LOADED_LAST_PAGE);
                } else {
                    setFetchState(LocationListFetchState.NO_RESULTS);
                    setLocations([]);
                }
            });
        });

        CapacitorApp.addListener("backButton", () => {
            console.log("BACK BUTTON PRESSED");
        });

        window.addEventListener("popstate", onPopState);

        const locationUuid = getQueryParameter(window.location.toString(), "location");

        if (locationUuid) {
            window.history.replaceState({
                location: null,
                ref: true
            }, "", "/");
            window.history.pushState({
                location: locationUuid
            }, "", "/?location=" + locationUuid);
        }

        return () => {
            props.locationFilterNotifier.unregisterHandler("main");
            window.removeEventListener("popstate", onPopState);
            CapacitorApp.removeAllListeners();
        };
    }, []);

    function onPopState(e: PopStateEvent) {
        if (e.state === null) {
            setOpenLocation(null);
        } else {
            openLocationWithUuid(e.state.location);
        }
    }

    useEffect(() => {
        const locationUuid = getQueryParameter(window.location.toString(), "location");
        if (locationUuid) {
            openLocationWithUuid(locationUuid);
        }
    });

    function openLocationWithUuid(locationUuid: string | null) {
        if (!locationUuid) setOpenLocation(null);

        const location = locationsRef.current?.find(location => location.uuid == locationUuid);
        if (location !== undefined) {
            setOpenLocation(location);
        }
    }

    function openLocationDialog(location: Location) {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set("location", location.uuid);
        window.history.pushState({
            location: location.uuid
        }, "", "/?" + urlParams.toString());
        setOpenLocation(location);
        locationDialogRef.current?.present();
    }

    function closeLocationDialog() {
        if (openLocation) {
            window.history.back();
        }
    }

    function loadMoreLocations() {
        props.apiConnector.getLocations(locationFilter, currentPage).then(
            (fetchedLocations: Location[] | null) => {
                if (!fetchedLocations) {
                    setFetchState(LocationListFetchState.NETWORK_ERROR);
                    setLocations([]);
                    setCurrentPage(1);
                } else if (fetchedLocations.length) {
                    setLocations(locations.concat(fetchedLocations));
                    setCurrentPage(currentPage + 1);

                    // We assume that if the server returned less than 10 results, we hit the last page of results
                    setFetchState(fetchedLocations.length === 10 ?
                        LocationListFetchState.LOADED_HAS_MORE :
                        LocationListFetchState.LOADED_LAST_PAGE);
                } else {
                    setFetchState(LocationListFetchState.LOADED_LAST_PAGE);
                }
            });

    }

    function onReload() {
        window.location.reload();
    }

    function NetworkError() {
        return (
            <Box className="center-status-box">
                <IonImg className="center-status-img" src={network_error}/>
                <h2>Hoppla!</h2>
                Die Verbindung zum Biss Server konnte nicht hergestellt werden
                <Box marginTop={4}>
                    <IonButton onClick={onReload} shape="round">Nochmals versuchen</IonButton>
                </Box>
            </Box>
        );
    }

    const hasNetworkConnection = () => fetchState !== LocationListFetchState.NETWORK_ERROR;

    return (
        <IonPage id="home-page">
            <IonHeader>
                <Header/>
            </IonHeader>
            <IonContent>
                {hasNetworkConnection() ? (
                    <IonSplitPane when="xl" contentId="main">
                        <IonMenu contentId="main">
                            <IonContent>
                                <Media query="(min-width: 1200px)" render={() => (
                                    <FilterComponent allFish={allFish}
                                                     allCantons={allCantons}
                                                     allTravelMethods={allTravelMethods}
                                                     fetchState={fetchState}
                                                     apiConnector={props.apiConnector}
                                                     locationFilterNotifier={props.locationFilterNotifier}/>
                                )}/>
                            </IonContent>
                        </IonMenu>

                        <Box className="ion-page" id="main">
                            <SearchBar onFilterModalOpen={() => setFilterModalOpen(true)}
                                       apiConnector={props.apiConnector}
                                       locationFilterNotifier={props.locationFilterNotifier}/>
                            <Box className="filter-preview-box">
                                <FilterPreview fetchState={fetchState}
                                               locationFilterNotifier={props.locationFilterNotifier}/>
                            </Box>
                            <IonContent>
                                <LocationList onOpenLocation={openLocationDialog}
                                              locationFetchState={fetchState}
                                              loadMoreLocations={loadMoreLocations}
                                              apiConnector={props.apiConnector} locations={locations}
                                              locationFilterNotifier={props.locationFilterNotifier}/>
                            </IonContent>
                        </Box>
                    </IonSplitPane>
                ) : <NetworkError/>}
            </IonContent>
            <IonModal keepContentsMounted={true}
                      className="location-dialog"
                      isOpen={openLocation != null}
                      ref={locationDialogRef}
                      onDidDismiss={closeLocationDialog}>
                {openLocation &&
                    <LocationView onClose={() => locationDialogRef.current?.dismiss()} location={openLocation}/>
                }
            </IonModal>
            <IonModal keepContentsMounted={true}
                      isOpen={isFilterModalOpen}
                      onWillDismiss={() => setFilterModalOpen(false)}>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonButton onClick={() => setFilterModalOpen(false)}>
                                Zurück
                            </IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <FilterComponent allFish={allFish}
                                     allCantons={allCantons}
                                     allTravelMethods={allTravelMethods}
                                     fetchState={fetchState}
                                     apiConnector={props.apiConnector}
                                     locationFilterNotifier={props.locationFilterNotifier}/>
                </IonContent>
            </IonModal>
        </IonPage>
    );
};

export default Home;
