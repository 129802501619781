import React from "react";
import ReactDOM from "react-dom";


import App from "./App";

// Disable console outputs in production
// if (process.env.NODE_ENV === "production") {
//     // eslint-disable-next-line @typescript-eslint/no-empty-function
//     console.log = () => {};
//     // eslint-disable-next-line @typescript-eslint/no-empty-function
//     console.error = () => {};
//     // eslint-disable-next-line @typescript-eslint/no-empty-function
//     console.debug = () => {};
// }

if (process.env.NODE_ENV === "production") {
    if ("serviceWorker" in navigator) {
        console.log("in navigator");
        window.addEventListener("load", () => {
            console.log("LOADD");
            navigator.serviceWorker.register("/service-worker.js").then(registration => {
                console.log("SW registered: ", registration);
            }).catch(registrationError => {
                console.log("SW registration failed: ", registrationError);
            });
        });
    } else {
        console.log("Not in navigator");
    }
}


ReactDOM.render(<App/>, document.getElementById("root"));