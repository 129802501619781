import "@biss/components/styles/SearchBar.sass";

import {SearchbarChangeEventDetail} from "@ionic/core";
import {IonButton, IonIcon, IonSearchbar} from "@ionic/react";
import {useMediaQuery} from "@mui/material";
import {funnelOutline} from "ionicons/icons";
import React from "react";

import {LocationFilterNotifier} from "@biss/lib/LocationFilterNotifier";
import {APIConnector} from "@biss/lib/APIConnector";

interface SearchBarProps {
    apiConnector: APIConnector;
    locationFilterNotifier: LocationFilterNotifier;

    onFilterModalOpen: () => void;
}

const SearchBar: React.FC<SearchBarProps> = (props: SearchBarProps) => {
    const matches = useMediaQuery("(max-width: 1199px)");

    function onSearchBarType(event: CustomEvent<SearchbarChangeEventDetail>) {
        const newState = {...props.locationFilterNotifier.currentState, searchQuery: event.detail.value ?? ""};
        props.locationFilterNotifier.trigger(newState);
    }

    return (
        <>
            <IonSearchbar className="search-bar"
                          debounce={200}
                          onIonInput={onSearchBarType}
                          placeholder="Suche"
                          animated>
                {matches ?
                    <IonButton slot="end" className="filter-button" color="light" onClick={props.onFilterModalOpen}>
                        <IonIcon icon={funnelOutline} slot="icon-only"/>
                    </IonButton> : <></>
                }
            </IonSearchbar>
        </>
    );
};

export default SearchBar;