import {LocationListFetchState} from "@biss/components/LocationList";
import PropertyChip, {ChipType, LocationProperty, TravelIconMap} from "@biss/components/PropertyChip";
import {Canton, emptyLocationFilter, Fish, LocationFilter, TravelMethod} from "@biss/data/Types";
import {APIConnector} from "@biss/lib/APIConnector";
import {LocationFilterNotifier} from "@biss/lib/LocationFilterNotifier";
import {IonChip, IonIcon, IonImg, IonLabel, IonSearchbar} from "@ionic/react";
import {Box} from "@mui/material";
import {fishOutline} from "ionicons/icons";
import React, {useEffect, useRef, useState} from "react";


import "./styles/FilterComponent.sass";


interface SearchFilterProps {
    apiConnector: APIConnector;
    locationFilterNotifier: LocationFilterNotifier;
    fetchState: LocationListFetchState;

    allFish: Fish[];
    allCantons: Canton[];
    allTravelMethods: TravelMethod[];
}

const FilterComponent: React.FC<SearchFilterProps> = (props) => {
    const [filteredCantons, setFilteredCantons] = useState<Canton[]>([]);
    const [filteredFish, setFilteredFish] = useState<Fish[]>([]);
    const [filterState, setFilterState] = useState<LocationFilter>(emptyLocationFilter());

    const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);

    useEffect(() => {
        props.locationFilterNotifier.registerHandler("searchFilter", (filter: LocationFilter) => {
            setFilterState(filter);
        });

        return () => props.locationFilterNotifier.unregisterHandler("searchFilter");
    }, []);

    // Make sure accordions are expanded after the loading of a resource
    useEffect(() => {
        if (accordionGroup.current) {
            accordionGroup.current.value = ["properties", "travel", "fish", "cantons"];
        }
    }, [props.allFish, props.allCantons, props.allTravelMethods]);

    useEffect(() => {
        updateCantonSearchQuery();
        updateFishSearchQuery();
    }, [props.allFish, props.allCantons]);

    function updateCantonSearchQuery(query?: string | undefined | null) {
        setFilteredCantons(
            props.allCantons
                .filter(canton => !query ? true : canton.name.toLowerCase().includes(query.toLowerCase()))
                .sort((c1, c2) => c1.name.localeCompare(c2.name))
        );
    }

    function updateFishSearchQuery(query?: string | undefined | null) {
        setFilteredFish(
            props.allFish
                .filter(fish => !query ? true : fish.name.toLowerCase().includes(query.toLowerCase()))
                .sort((f1, f2) => f1.name.localeCompare(f2.name))
        );
    }

    function updateFilter(filterState: LocationFilter) {
        // Block filter change during loading
        if (props.fetchState === LocationListFetchState.LOADING) return;

        props.locationFilterNotifier.trigger(filterState);
    }

    return (
        <Box marginLeft={2} marginRight={2} marginBottom={2}>
            <h4> Angebote </h4>
            <Box marginLeft={1}>
                <PropertyChip small={false}
                              type={ChipType.FILTER}
                              active={filterState.propertiesFilter.has(LocationProperty.RequireBoatRent)}
                              property={LocationProperty.RequireBoatRent}
                              onClick={() => updateFilter({
                                  ...filterState,
                                  propertiesFilter:
                                      filterState.propertiesFilter.toggle(LocationProperty.RequireBoatRent)
                              })}/>
                <PropertyChip small={false}
                              type={ChipType.FILTER}
                              active={filterState.propertiesFilter.has(LocationProperty.RequireCamping)}
                              property={LocationProperty.RequireCamping}
                              onClick={() => updateFilter({
                                  ...filterState,
                                  propertiesFilter:
                                      filterState.propertiesFilter.toggle(LocationProperty.RequireCamping)
                              })}/>
                <PropertyChip small={false}
                              type={ChipType.FILTER}
                              active={filterState.propertiesFilter.has(LocationProperty.RequireWithoutSana)}
                              property={LocationProperty.RequireWithoutSana}
                              onClick={() => updateFilter({
                                  ...filterState,
                                  propertiesFilter:
                                      filterState.propertiesFilter.toggle(LocationProperty.RequireWithoutSana)
                              })}/>
                <PropertyChip small={false}
                              type={ChipType.FILTER}
                              active={filterState.propertiesFilter.has(LocationProperty.RequireFreeFishing)}
                              property={LocationProperty.RequireFreeFishing}
                              onClick={() => updateFilter({
                                  ...filterState,
                                  propertiesFilter:
                                      filterState.propertiesFilter.toggle(LocationProperty.RequireFreeFishing)
                              })}/>
                <PropertyChip small={false}
                              type={ChipType.FILTER}
                              active={filterState.propertiesFilter.has(LocationProperty.RequireRestaurant)}
                              property={LocationProperty.RequireRestaurant}
                              onClick={() => updateFilter({
                                  ...filterState,
                                  propertiesFilter:
                                      filterState.propertiesFilter.toggle(LocationProperty.RequireRestaurant)
                              })}/>
            </Box>

            <h4> Anreise </h4>
            <Box marginLeft={1}>
                {props.allTravelMethods.sort().map((travelMethod, i) => (
                    <IonChip key={i}
                             outline={!filterState.travelMethodFilter.has(travelMethod)}
                             onClick={() => updateFilter({
                                 ...filterState,
                                 travelMethodFilter: filterState.travelMethodFilter.toggle(travelMethod)
                             })}>
                        <IonIcon icon={TravelIconMap.get(travelMethod.type)}/>
                        <IonLabel> {travelMethod.name} </IonLabel>
                    </IonChip>
                ))}
            </Box>

            <h4> Fische </h4>
            <Box marginLeft={1} marginRight={1}>
                <IonSearchbar onIonInput={(e) => updateFishSearchQuery(e.detail.value)}
                              placeholder="z.B. Forelle"
                              animated>
                </IonSearchbar>
                <Choose>
                    <When condition={filteredFish.length}>
                        <Box>
                            {filteredFish.map((fish, i) => (
                                <IonChip key={i}
                                         outline={!filterState.fishFilter.has(fish)}
                                         onClick={() => updateFilter({
                                             ...filterState,
                                             fishFilter: filterState.fishFilter.toggle(fish)
                                         })}>
                                    <IonIcon icon={fishOutline}/>
                                    <IonLabel> {fish.name} </IonLabel>
                                </IonChip>
                            ))}
                        </Box>
                    </When>
                    <Otherwise>
                        <Box className="ion-margin ion-text-center"> Keine Fische gefunden </Box>
                    </Otherwise>
                </Choose>
            </Box>

            <h4> Kantone </h4>
            <Box marginLeft={1} marginRight={1}>
                <IonSearchbar onIonInput={(e) => updateCantonSearchQuery(e.detail.value)}
                              placeholder="z.B. Graubünden"
                              animated>
                </IonSearchbar>
                <Choose>
                    <When condition={filteredCantons.length}>
                        <Box>
                            {filteredCantons.map((canton, i) => (
                                <IonChip key={i} outline={!filterState.cantonFilter.has(canton)}
                                         onClick={() => updateFilter({
                                             ...filterState,
                                             cantonFilter: filterState.cantonFilter.toggle(canton)
                                         })}>
                                    <IonImg className="canton-flag-chip-img"
                                            src={APIConnector.getCantonFlagUrl(canton)}/>
                                    <IonLabel> {canton.name} </IonLabel>
                                </IonChip>
                            ))}
                        </Box>
                    </When>
                    <Otherwise>
                        <Box className="ion-margin ion-text-center"> Keine Kantone gefunden </Box>
                    </Otherwise>
                </Choose>
            </Box>
        </Box>
    );
};

export default FilterComponent;