import {emptyLocationFilter, LocationFilter} from "@biss/data/Types";

export class LocationFilterNotifier {
    public currentState: LocationFilter = emptyLocationFilter();
    private observers: Map<string, ((filter: LocationFilter) => void)> = new Map();

    constructor() {
        this.resetFilter = this.resetFilter.bind(this);
    }

    resetFilter() {
        this.trigger(emptyLocationFilter());
    }

    registerHandler(callbackName: string, callback: (filter: LocationFilter) => void) {
        this.observers.set(callbackName, callback);
    }

    unregisterHandler(callbackName: string) {
        this.observers.delete(callbackName);
    }

    trigger(filter: LocationFilter) {
        this.currentState = filter;
        this.observers.forEach(observerCallback => observerCallback(this.currentState));
    }
}