import {useEffect, useState} from "react";

/**
 * Set that supports a function that adds or removes a value from a set based on its presence in the set
 */
export class ToggleSet<T> extends Set<T> {
    toggle(item: T) {
        if (super.has(item)) {
            super.delete(item);
        } else {
            super.add(item);
        }
        return this;
    }

    toList() {
        return [...this];
    }
}

/**
 * Read-only hook to fetch stateless resources once when loading a component for the first time
 * @param defaultValue Default value that is used before the actual resource value was fetched
 * @param fetcherCallback A function that returns a promise that resolves to the actual resource value
 */
export function useResource<T>(defaultValue: T, fetcherCallback: () => Promise<T>) {
    const [fetched, setFetched] = useState(false);
    const [value, setValue] = useState<T>(defaultValue);

    useEffect(() => {
        if (!fetched) {
            console.log("Fetching static resources");
            fetcherCallback().then(result => setValue(result));
            setFetched(true);
        }
    });
    return value;
}

export const arrayOf = (size: number) => [...new Array(size).keys()];

export function setQueryParameter(key: string, value: string) {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set(key, value);
    window.history.pushState({
        key: value
    }, "", "/?" + urlParams.toString());
}

export function removeQueryParams() {
    window.history.back();
}

export function getQueryParameter(url: string, key: string): string | null {
    const urlParams = new URL(url).searchParams;
    return urlParams.has(key) ? urlParams.get(key) : null;
}