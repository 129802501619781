import {IonIcon, IonItem, IonLabel} from "@ionic/react";
import {Box, Collapse, Fade} from "@mui/material";
import {eyeOutline} from "ionicons/icons";
import Media from "react-media";
import React, {useState} from "react";

import {APIConnector} from "@biss/lib/APIConnector";
import {FishLink} from "@biss/data/Types";

interface FishEntryProps {
    fishLink: FishLink;
}

const MONTH_NAMES = [
    "Januar", "Februar", "März", "April", "Mai", "Juni",
    "Juli", "August", "September", "Oktober", "November", "Dezember"
];

const FishListEntry: React.FC<FishEntryProps> = (props) => {
    const [fishCollapseMap, setFishCollapseMap] = useState<Map<string, boolean>>(new Map());

    const onFishClick = (fishName: string) =>
        setFishCollapseMap(new Map(fishCollapseMap.set(fishName, !fishCollapseMap.get(fishName))));

    const getMinimumSize = (fishLink: FishLink) =>
        fishLink.minimumSize ? `${fishLink.minimumSize} cm` : "Kein Schonmass";

    const getProtectionPeriod = (fishLink: FishLink) => {
        if (!fishLink.protectionStart || !fishLink.protectionEnd) return "Keine Schonzeit";

        const start = new Date(Date.parse(fishLink.protectionStart));
        const end = new Date(Date.parse(fishLink.protectionEnd));

        return `${start.getDate()}. ${MONTH_NAMES[start.getMonth()]} bis ${end.getDate()}. ${MONTH_NAMES[end.getMonth()]}`;
    };

    return (
        <Media queries={{small: "(max-width: 590px)"}}>
            {matches => (
                <IonItem onClick={() => matches.small ? onFishClick(props.fishLink.fish.name) : {}}
                         key={props.fishLink.fish.uuid}>
                    <Box className="fish-entry" sx={{
                        display: "flex",
                        flexGrow: 1,
                        flexDirection: "row",
                        alignItems: "center"
                    }}>
                        <Collapse orientation="horizontal"
                                  in={matches.small && !fishCollapseMap.get(props.fishLink.fish.name)}>
                            <IonIcon icon={eyeOutline} className="flip-indicator"/>
                        </Collapse>
                        <Collapse orientation="horizontal"
                                  in={!matches.small || fishCollapseMap.get(props.fishLink.fish.name)}>
                            <img alt={props.fishLink.fish.name}
                                 className="fish-icon"
                                 src={APIConnector.getFishImageByName(props.fishLink.fish.name)}/>
                        </Collapse>
                        <Collapse orientation="horizontal"
                                  in={!matches.small || !fishCollapseMap.get(props.fishLink.fish.name)}>
                            <IonLabel>
                                <h2 className="fish-name">{props.fishLink.fish.name}</h2>
                                <p>
                                    <b> Schonmass: </b> {getMinimumSize(props.fishLink)}
                                </p>
                                <p>
                                    <b> Schonzeit: </b> {getProtectionPeriod(props.fishLink)}
                                </p>
                            </IonLabel>
                        </Collapse>
                        <Fade in={matches.small && fishCollapseMap.get(props.fishLink.fish.name)}>
                            <IonLabel>
                                <h2 className="fish-name">{props.fishLink.fish.name}</h2>
                            </IonLabel>
                        </Fade>
                    </Box>
                </IonItem>
            )}
        </Media>
    );
};

export default FishListEntry;